import React from "react";
import * as styles from "./LibraryCases.module.scss";
import LibraryIcon from '../../../svg/library-icon.svg';
import AutoPlaySlider from "../../molecules/AutoPlaySlider/AutoPlaySlider";
import { navigate } from "gatsby";

function LibraryCases({data, title, subtitle}){
    return(
        <div className={styles.container}>
            <div className={styles.content}>
             <div className={styles.text}>
              <h1>{title} <br/> {subtitle}</h1>
              <div className={styles.btns}>
              {/* <button>See all Cases</button> */}
              <button onClick={() => navigate("/contact")}>Contact us to learn more</button>
              </div>
             </div>
            </div>
            <AutoPlaySlider bg="black" extraClass={"brightmode"} data={data}/>
            {/* <AutoPlaySlider autoPlaySpeed={250} extraClass={"brightmodesecondrow"} {...{data}}/> */}

        </div>
    );
}
export default LibraryCases;