import React from "react";
import * as styles from "./AutoPlayCases.module.scss";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";
import logo from "../../../images/getwizr2.png";
import AutoPlaySlider from "../AutoPlaySlider/AutoPlaySlider";
import { navigate } from "gatsby";
function AutoPlayCases({video, data, extraClass, title, subtitle, backgroundImage, mpublisher}) {


  return (
    <div className={styles.container}>
      <div className={styles.textHolder}  style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className={styles.content}> ⁄
        <h1>
          {title} <br/> <span className={styles.subtitle}>{subtitle}</span>
        </h1>
      </div>
      </div>
      <div className={styles.videoContainer}>
          <video src="https://res.cloudinary.com/dsg2ktuqk/video/upload/v1713207112/Templates/Video_Web_2_P1_kun6r6.mp4" autoPlay={true} loop muted />
      </div>
    <AutoPlaySlider extraClass={extraClass} data={data} video={video}/>
    {mpublisher && 
      <h1 className={styles.mpublisherTitle}>MPublisher - Get creative ideas</h1>
    }
    </div>
  );
}

export default AutoPlayCases;
