import Slider from "react-slick";
import React from "react";
import * as styles from "./UserSlider.module.scss";
import ArrowRight from "../../../svg/icon-arrow-right.svg";
import ArrowLeft from "../../../svg/icon-arrow-left.svg";
const UserSlider = () => {
  const settings = {
    centerMode: true,
    slidesToShow: 1,
    infinite: true,
    dots: false,
    arrows:true,
    prevArrow:<div><ArrowLeft/></div>,
    nextArrow:<div><ArrowRight /></div>,
    centerPadding: '28%',

  };
 const data = [
   {
       image:"https://live.point-us.de/wp-content/uploads/2023/03/about-uou-8-1-scaled.jpg",
       title:`<span>Marketing</span> User Case`,
       image1:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858155/streaming1_rxdh3o.png",
       alt1: "Presentation slide in Master Wizr's Library with a colorful background and artistic vector art model.",
       image2:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858155/streaming2_wqwmaq.png",
       alt2: "Streaming View in Master Wizr Library displaying contents in a grid, similar to streaming platforms."
   },
{
    image:"https://live.point-us.de/wp-content/uploads/2023/03/about-uou-9-1-scaled.jpg",
    title:`<span>Hobbies</span> User Case`,
    image1:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679857486/topstudio_mnev6v.png",
    alt1: "Poster in Master Wizr Studio promoting reasons to visit Africa.",
    image2:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679857485/bottomstudio_n7vvgw.png",
    alt2: "Showrooms feature in Master Wizr's Studio letting users share multiple contents elegantly with a single link."
},
{
    image:"https://live.point-us.de/wp-content/uploads/2023/03/about-uou-4-6-scaled.jpg",
    title:`<span>Interests</span> User Case`,
    image1:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858428/articlescase_fevoz6.png",
    alt1: "Poster displayed in Master Wizr Studio.",
    image2:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858428/articlescasebt_gkj2ax.png",
    alt2: "Snapshot of Master Wizr Studio's Showroom showcasing three compelling contents.",
},
{
    image:"https://live.point-us.de/wp-content/uploads/2023/03/articles-discover-1-scaled.jpg",
    title:`<span>Hobbies</span> User Case`,
    image1:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858428/articlescase_fevoz6.png",
    alt1:"Poster displayed in Master Wizr Studio.",
    image2:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1679858428/articlescasebt_gkj2ax.png",
    alt2: "Snapshot of Master Wizr Studio's Showroom showcasing three compelling contents.",
},
]
  return (
  <div className={`${styles.container} userSlider`}>
              <h1>Cover page + Show Room examples...</h1>
    <Slider {...settings}>
    {data.map((item,i)=>{
        return(
          <div className={styles.item}>
              <h1></h1>
               <div className={styles.images}>
                   <img src={item.image1} alt={item?.alt1}/>
                   <img src={item.image2}alt={item?.alt2}/>
              </div>
          </div> 
        )
    })}
    </Slider>
  </div>
  );
};
export default UserSlider;