import React from "react";
import * as styles from "./BrightAutoPlay.module.scss";
import StudioIcon from '../../../svg/studio-icon.svg';
import AutoPlaySlider from "../AutoPlaySlider/AutoPlaySlider";
import { navigate } from "gatsby";

function BrightAutoPlay({video, data, title, subtitle}){
    return(
        <div className={styles.container}>
            <div className={styles.content}>
             <div className={styles.text}>
              <h1>{title} <br/> {subtitle}</h1>
              <div className={styles.btns}>
              <button onClick={() => navigate("/contact")}>Contact Us</button>
              </div>
             </div>
            </div>
            <AutoPlaySlider bg="black" extraClass={"brightmode"} data={data} video={video}/>
        </div>
    );
}
export default BrightAutoPlay;